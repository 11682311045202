let mailboxItem;
let apiToken;

let eventContext;
let to;
let cc;
let bcc;
let subject;
let body;
let files;

Office.onReady((info) => {
  if (info.host === Office.HostType.Outlook) {
    // Ensure mailbox is available
    if (Office.context.mailbox) {
      mailboxItem = Office.context.mailbox.item;

      // Check if the ApiToken is already set in RoamingSettings
      apiToken = Office.context.roamingSettings.get("apiToken");
      console.log("ApiToken retrieved from RoamingSettings: " + apiToken);
    } else {
      console.error("Office.context.mailbox is not available.");
      //document.getElementById("statusMessage").innerText = "Office.context.mailbox is not available.";
    }
  } else {
    console.error("Not running in Outlook.");
    //document.getElementById("statusMessage").innerText = "Not running in Outlook.";
  }
});

// Entry point for Message Body Checker add-in before send is allowed.
// <param name="event">MessageSend event is automatically passed by BlockOnSend code to the function specified in the manifest.</param>
function validateEmail(event) {
  if (mailboxItem) {
    eventContext = event;
    Promise.all([
      getToOfMailboxItem(),
      getCcOfMailboxItem(),
      getBccOfMailboxItem(),
      getSubjectOfMailboxItem(),
      getBodyOfMailboxItem(),
      getAttachmentsOfMailboxItem(),
    ])
      .then(() => {
        console.log("All fields retrieved successfully. Opening dialog...");
        openDialog();
      })
      .catch((error) => {
        console.error("Failed to retrieve email fields: " + error);
        event.completed({ allowEvent: true });
      });
  } else {
    // Log or handle the case where mailboxItem is not available
    event.completed({ allowEvent: true });
  }
}

// Retrieves the field "To" of the mailboxItem and set the global "to" variable
function getToOfMailboxItem() {
  if (!mailboxItem) return Promise.reject("No mailboxItem available");
  console.log("Getting To field...");
  return new Promise((resolve) => {
    mailboxItem.to.getAsync((asyncResult) => {
      to =
        asyncResult.value?.length > 0 ? asyncResult.value.map((recipient) => recipient.emailAddress).join(",") : null;
      resolve(to);
    });
  });
}

// Retrieves the field "CC" of the mailboxItem and set the global "cc" variable
function getCcOfMailboxItem() {
  if (!mailboxItem) return Promise.reject("No mailboxItem available");
  console.log("Getting CC field...");
  return new Promise((resolve) => {
    mailboxItem.cc.getAsync((asyncResult) => {
      cc =
        asyncResult.value?.length > 0 ? asyncResult.value.map((recipient) => recipient.emailAddress).join(",") : null;
      resolve(cc);
    });
  });
}

// Retrieves the field "Bcc" of the mailboxItem and set the global "bcc" variable
function getBccOfMailboxItem() {
  if (!mailboxItem) return Promise.reject("No mailboxItem available");
  console.log("Getting BCC field...");
  return new Promise((resolve) => {
    mailboxItem.bcc.getAsync((asyncResult) => {
      bcc =
        asyncResult.value?.length > 0 ? asyncResult.value.map((recipient) => recipient.emailAddress).join(",") : null;
      resolve(bcc);
    });
  });
}

// Retrieves the field "Subject" of the mailboxItem and set the global "subject" variable
function getSubjectOfMailboxItem() {
  if (!mailboxItem) return Promise.reject("No mailboxItem available");
  console.log("Getting Subject field...");
  return new Promise((resolve) => {
    mailboxItem.subject.getAsync((asyncResult) => {
      subject = asyncResult.value ? asyncResult.value : null;
      resolve(subject);
    });
  });
}

// Retrieves the field "Body" of the mailboxItem and set the global "body" variable
function getBodyOfMailboxItem() {
  if (!mailboxItem) return Promise.reject("No mailboxItem available");
  console.log("Getting Body field...");
  return new Promise((resolve) => {
    mailboxItem.body.getAsync("text", (asyncResult) => {
      body = asyncResult.value ? asyncResult.value : null;
      resolve(body);
    });
  });
}

// Retrieves the attachments of the mailboxItem and set the global "attachments" variable
function getAttachmentsOfMailboxItem() {
  if (!mailboxItem) return Promise.reject("No mailboxItem available");
  console.log("Getting attachments...");
  return new Promise((resolve, reject) => {
    files = [];
    Office.context.mailbox.item.getAttachmentsAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        reject(result.error.message);
      }

      if (result.value.length <= 0) {
        resolve(files);
      }

      Promise.all(
        result.value
          .filter((attachment) => attachment.attachmentType === Office.MailboxEnums.AttachmentType.File)
          .map((attachment) => {
            return new Promise((res, rej) =>
              getAttachmentContentOfMailboxItem(attachment.id)
                .then((content) => {
                  files.push({
                    id: attachment.id,
                    filename: attachment.name,
                    size: attachment.size,
                    base64Content: content,
                  });
                  res();
                })
                .catch((error) => {
                  rej(error);
                })
            );
          })
      )
        .then(() => {
          resolve(files);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}

// Retrieves an attachments content
function getAttachmentContentOfMailboxItem(attachmentId) {
  if (!mailboxItem) return Promise.reject("No mailboxItem available");
  if (!attachmentId) return Promise.reject("No attachmentId available");
  console.log("Getting attachment content of id: " + attachmentId + "...");
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.getAttachmentContentAsync(attachmentId, (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        reject(result.error.message);
      }
      switch (result.value.format) {
        case Office.MailboxEnums.AttachmentContentFormat.Base64:
          // Handle file attachment.
          resolve(result.value.content);
          break;
        case Office.MailboxEnums.AttachmentContentFormat.Eml:
          resolve(null);
          // Handle email item attachment.
          break;
        case Office.MailboxEnums.AttachmentContentFormat.ICalendar:
          // Handle .icalender attachment.
          resolve(null);
          break;
        case Office.MailboxEnums.AttachmentContentFormat.Url:
          // Handle cloud attachment.
          resolve(null);
          break;
        default:
          // Handle attachment formats that are not supported.
          resolve(null);
      }
    });
  });
}

// Returns the redactedValue of a message based on its label
function getMessageValueToBeSendByLabel(messages, label) {
  const message = messages.filter((msg) => msg.label === label);
  if (message.length > 0) {
    if (message[0].submitOriginal || !message[0].redactedValue || message[0].redactedValue === "no data") {
      return null;
    } else {
      return message[0].redactedValue;
    }
  }

  return null;
}

// Sets the "to" field of the mailboxItem
function setToField(to) {
  if (!to) {
    return Promise.resolve(); // Skip setting the field
  }
  return new Promise((resolve, reject) => {
    mailboxItem.to.setAsync(
      to.split(",").filter((recipient) => !recipient.includes("<") || !recipient.includes(">")),
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          console.log("Succeeded in setting To field.");
          resolve();
        } else {
          console.error("Failed to update email To: " + asyncResult.error.message);
          reject(asyncResult.error.message);
        }
      }
    );
  });
}

// Sets the "CC" field of the mailboxItem
function setCcField(cc) {
  if (!cc) {
    return Promise.resolve(); // Skip setting the field
  }
  return new Promise((resolve, reject) => {
    mailboxItem.cc.setAsync(
      cc.split(",").filter((recipient) => !recipient.includes("<") || !recipient.includes(">")),
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          console.log("Succeeded in setting CC field.");
          resolve();
        } else {
          console.error("Failed to update email CC: " + asyncResult.error.message);
          reject(asyncResult.error.message);
        }
      }
    );
  });
}

// Sets the "BCC" field of the mailboxItem
function setBccField(bcc) {
  if (!bcc) {
    return Promise.resolve(); // Skip setting the field
  }
  return new Promise((resolve, reject) => {
    mailboxItem.bcc.setAsync(
      bcc.split(",").filter((recipient) => !recipient.includes("<") || !recipient.includes(">")),
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          console.log("Succeeded in setting BCC field.");
          resolve();
        } else {
          console.error("Failed to update email BCC: " + asyncResult.error.message);
          reject(asyncResult.error.message);
        }
      }
    );
  });
}

// Sets the "Subject" field of the mailboxItem
function setSubjectField(subject) {
  if (!subject) {
    return Promise.resolve(); // Skip setting the field
  }
  return new Promise((resolve, reject) => {
    mailboxItem.subject.setAsync(subject, function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log("Succeeded in setting Subject field.");
        resolve();
      } else {
        console.error("Failed to update email Subject: " + asyncResult.error.message);
        reject(asyncResult.error.message);
      }
    });
  });
}

// Sets the "body" field of the mailboxItem
function setBodyFieldAsHTML(body) {
  if (!body) {
    return Promise.resolve(); // Skip setting the field
  }
  return new Promise((resolve, reject) => {
    mailboxItem.body.setAsync(body, { coercionType: "html" }, function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log("Succeeded in setting Body field.");
        resolve();
      } else {
        console.error("Failed to update email Body: " + asyncResult.error.message);
        reject(asyncResult.error.message);
      }
    });
  });
}

// Remove the attachment from the mailboxItem
function removeAttachment(attachmentId) {
  if (!attachmentId) {
    return Promise.resolve(); // Skip removing attachment
  }
  return new Promise((resolve, reject) => {
    mailboxItem.removeAttachmentAsync(attachmentId, function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log("Succeeded in removing attachment " + attachmentId);
        resolve();
      } else {
        console.error("Failed to remove attachment " + attachmentId + ": " + asyncResult.error.message);
        reject(asyncResult.error.message);
      }
    });
  });
}

// Checks files for attachments that should be removed and also removes them
function checkFilesAndRemoveFromAttachments(files) {
  if (!files) {
    return Promise.resolve(); // Skip checking files and removing them from attachments
  }
  return new Promise((resolve, reject) => {
    Promise.all(
      files
        .filter((file) => file.shouldBeRemoved)
        .map((file) => {
          return new Promise((res, rej) => {
            removeAttachment(file.id)
              .then(() => {
                res();
              })
              .catch((error) => {
                rej(error);
              });
          });
        })
    )
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Open an HTML dialog to notify the user about blocked words and prompt them to take action.
function openDialog() {
  const dialogOptions = { width: 50, height: 45, displayInIframe: true };
  // Open the dialog
  Office.context.ui.displayDialogAsync(
    `https://localhost:3050/scan-dialog.html`,
    dialogOptions,
    function (asyncResult) {
      const dialog = asyncResult.value;

      // Listen for the dialogReady message
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (message) {
        if (message?.message === "dialogReady") {
          // Send a message to the dialog
          dialog.messageChild(
            JSON.stringify({
              apiToken: Office.context.roamingSettings.get("apiKey"), // Use the apiToken from the roaming settings or elsewhere
              messages: [
                {
                  label: "To",
                  value: to,
                },
                {
                  label: "CC",
                  value: cc,
                },
                {
                  label: "BCC",
                  value: bcc,
                },
                {
                  label: "Subject",
                  value: subject,
                },
                {
                  label: "Body",
                  value: body,
                },
              ],
              files: files,
            })
          );
        }

        const response = JSON.parse(message?.message);
        console.log(response);

        if (response.status === "ok") {
          // If response.message is present, set response.message as email body
          if (response.messages) {
            const escapedMessages = response.messages.map((msg) => {
              if (msg.label === "Body") {
                return {
                  ...msg,
                  redactedValue: msg.redactedValue.replace(/</g, "&lt;").replace(/>/g, "&gt;"),
                };
              }
              return msg;
            });

            const to = getMessageValueToBeSendByLabel(escapedMessages, "To");
            const cc = getMessageValueToBeSendByLabel(escapedMessages, "CC");
            const bcc = getMessageValueToBeSendByLabel(escapedMessages, "BCC");
            const subject = getMessageValueToBeSendByLabel(escapedMessages, "Subject");
            const body = getMessageValueToBeSendByLabel(escapedMessages, "Body");
            Promise.all([
              setToField(to),
              setCcField(cc),
              setBccField(bcc),
              setSubjectField(subject),
              setBodyFieldAsHTML(body),
              checkFilesAndRemoveFromAttachments(response.files),
            ])
              .then(() => {
                console.log("All fields updated successfully.");
                eventContext.completed({ allowEvent: true });
                dialog.close();
              })
              .catch((error) => {
                console.error("Failed to update email fields: " + error);
                eventContext.completed({ allowEvent: false });
                dialog.close();
              });
          } else {
            eventContext.completed({ allowEvent: true });
            dialog.close();
          }
        } else if (response.status === "cancel") {
          // Block the email send and close the dialog
          mailboxItem.notificationMessages.addAsync("NoSend", {
            type: "errorMessage",
            message:
              "1Protection.AI found sensitive data in your input. Your organisation requires you to review this email before it is submitted.",
          });

          // Disallow the email to be sent and close the dialog
          eventContext.completed({ allowEvent: false });
          dialog.close();
        }
      });

      dialog.addEventHandler(Office.EventType.DialogEventReceived, function () {
        // If the dialog is closed without an action, block the send event
        mailboxItem.notificationMessages.addAsync("NoSend", {
          type: "errorMessage",
          message:
            "1Protection.AI found sensitive data in your input. Your organisation requires you to review this email before it is submitted.",
        });
        eventContext.completed({ allowEvent: false });
        dialog.close();
      });
    }
  );
}